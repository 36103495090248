
import { useEffect, useRef, useState } from "react"

import { GoogleMap, LoadScriptNext, InfoWindow, OverlayView } from '@react-google-maps/api';
import MarkerPin from '../../UI/Icons/MarkerPin'
import Dialog from './Dialog';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import useWindowSize from '@hooks/useWindowSize';
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

import styles from './Map.module.scss'
import {useTranslation} from "next-i18next";

interface FieldData {
  fieldData: any;
  field_map_content: any;
}

gsap.registerPlugin(ScrollTrigger);

export default function Map({ fieldData: { field_map_content } }: FieldData) {
  const {t} = useTranslation();
  const colorMap = {
    [t('paragraphs.map.tipologies.districtHeating')]: "#001A70",
    [t('paragraphs.map.tipologies.circularEconomy')]: "#0E6814",
    [t('paragraphs.map.tipologies.greenHydrogen')]: "#6095DB",
    [t('paragraphs.map.tipologies.biodiversity')]: "#1057C8",
    [t('paragraphs.map.tipologies.culturalHeritage')]: "#4E9E30",
  }

  const size = useWindowSize();
  const [currentMarker, setCurrentMarker] = useState<any>();
  const [markerInfo, setMarkerInfo] = useState<any>();
  const [locations, setLocations] = useState([]);
  const [baseLocations, setBaseLocations] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [filterTypologies, setFilterTypologies] = useState([])
  const [map, setMap] = useState<any>();
  const [zoom, setZoom] = useState(5)
  const onMarkerClick = (e: any) => {
    setCurrentMarker(e)
  }

  const pins = [
    {
      "Nome Impianto": "Cerialdo",
      "Latitudine": 44.4072225709959,
      "Longitudine": 7.53896759381581,
      "Stato": "In esercizio",
      [t('paragraphs.map.tipologies.label')]: t('paragraphs.map.tipologies.districtHeating'),
      "Lunghezza Rete [Km]": "1,65",
      "tipologia utenze": "Residenziali e Terziario",
      "Tecnologia di generazione": "Biomassa + Gas",
      "Potenza [MWt]": 3,
    },
    {
      "Nome Impianto": "Robilante",
      "Latitudine": 44.2941968248661,
      "Longitudine": 7.51273769040579,
      "Stato": "In esercizio",
      [t('paragraphs.map.tipologies.label')]: t('paragraphs.map.tipologies.districtHeating'),
      "Lunghezza Rete [Km]": "0,90",
      "tipologia utenze": "Residenziali e Terziario",
      "Tecnologia di generazione": "Biomassa + Gas",
      "Potenza [MWt]": "1,83",
    },
    {
      "Nome Impianto": "Vernante",
      "Latitudine": 44.2460568304685,
      "Longitudine": 7.53242856020798,
      "Stato": "In esercizio",
      [t('paragraphs.map.tipologies.label')]: t('paragraphs.map.tipologies.districtHeating'),
      "Lunghezza Rete [Km]": "4,65",
      "tipologia utenze": "Residenziali e Terziario",
      "Tecnologia di generazione": "Biomassa + Gas",
      "Potenza [MWt]": "1,1",
    },
    {
      "Nome Impianto": "Barge",
      "Latitudine": 44.7301192218088,
      "Longitudine": 7.34022150717662,
      "Stato": "In esercizio",
      [t('paragraphs.map.tipologies.label')]: t('paragraphs.map.tipologies.districtHeating'),
      "Lunghezza Rete [Km]": "4,65",
      "tipologia utenze": "Residenziali, Terziario e Industriali",
      "Tecnologia di generazione": "Cogenerazione+Biomassa+Gas",
      "Potenza [MWt]": "2,67",
    },
    {
      "Nome Impianto": "Alzano Lombardo",
      "Latitudine": 45.7324494802071,
      "Longitudine": 9.73508602061063,
      "Stato": "In esercizio",
      [t('paragraphs.map.tipologies.label')]: t('paragraphs.map.tipologies.districtHeating'),
      "Lunghezza Rete [Km]": "5,70",
      "tipologia utenze": "Residenziali, Terziario e Industriali",
      "Tecnologia di generazione": "Cogenerazione+Gas",
      "Potenza [MWt]": "8,86",
    },
    {
      "Nome Impianto": "Busca",
      "Latitudine": 44.5242439240116,
      "Longitudine": 7.48683297662424,
      "Stato": "In esercizio",
      [t('paragraphs.map.tipologies.label')]: t('paragraphs.map.tipologies.districtHeating'),
      "Lunghezza Rete [Km]": "6,55",
      "tipologia utenze": "Residenziali, Terziario e Industriali",
      "Tecnologia di generazione": "Turbina+Biomassa+Gas",
      "Potenza [MWt]": "9,35",
    },
    {
      "Nome Impianto": "Valdieri",
      "Latitudine": 44.277731400798,
      "Longitudine": 7.39639305615774,
      "Stato": "In esercizio",
      [t('paragraphs.map.tipologies.label')]: t('paragraphs.map.tipologies.districtHeating'),
      "Lunghezza Rete [Km]": "0,50",
      "tipologia utenze": "Residenziali e Terziario",
      "Tecnologia di generazione": "Biomassa + Gas",
      "Potenza [MWt]": "0,99",
    },
    {
      "Nome Società": "RENDINA AMBIENTE SRL",
      "Nome Impianto": "Termovalorizzatore Melfi",
      "Latitudine": 41.0717230596554,
      "Longitudine": 15.7025217080374,
      "Stato": "In esercizio",
      [t('paragraphs.map.tipologies.label')]: t('paragraphs.map.tipologies.circularEconomy'),
      "Tipologia impianto": "Termovalorizzatore",
      "Tipo Produzione": "Energia elettrica",
      "Tipologia Rifiuti": "RIFIUTI INDUSTRIALI",
      "Quantità Rifiuti Trattati [t/anno]": "49,5"
    },
    {
      "Nome Società": "AMBYENTA SRL",
      "Nome Impianto": "Biometano Ambyenta Zinasco",
      "Latitudine": 45.1251560488481,
      "Longitudine": 9.02902569936431,
      "Stato": "In riconversione (biometano)",
      [t('paragraphs.map.tipologies.label')]: t('paragraphs.map.tipologies.circularEconomy'),
      "Tipologia impianto": "Produzione Biogas",
      "Tipo Produzione": "Energia elettrica",
      "Tipologia Rifiuti": "FORSU",
      "Quantità Rifiuti Trattati [t/anno]": "22,2"
    },
    {
      "Nome Società": "CEA BIOGAS SRL",
      "Nome Impianto": "Biogas CEA Caivano",
      "Latitudine": 40.9564440323242,
      "Longitudine": 14.3084203211063,
      "Stato": "In riconversione (biometano)",
      [t('paragraphs.map.tipologies.label')]: t('paragraphs.map.tipologies.circularEconomy'),
      "Tipologia impianto": "Produzione Biogas",
      "Tipo Produzione": "Energia elettrica",
      "Tipologia Rifiuti": "FORSU",
      "Quantità Rifiuti Trattati [t/anno]": "31,2"
    },
    {
      "Nome Società": "AMBYENTA LAZIO SRL",
      "Nome Impianto": "Biometano Ambyenta Lazio Civitavecchia",
      "Latitudine": 42.088403788462,
      "Longitudine": 11.7963874760193,
      "Stato": "Progetto",
      [t('paragraphs.map.tipologies.label')]: t('paragraphs.map.tipologies.circularEconomy'),
      "Tipologia impianto": "Produzione Biogas e Biometano",
      "Tipo Produzione": "Biometano",
      "Tipologia Rifiuti": "FORSU",
      "Quantità Rifiuti Trattati [t/anno]": ""
    },
    {
      "Nome Società": "ECOLOGICA MARCHE SRL",
      "Nome Impianto": "Trattamento Intermedio Ecologica Corridonia",
      "Latitudine": 43.250088638074,
      "Longitudine": 13.5134389705303,
      "Stato": "In esercizio",
      [t('paragraphs.map.tipologies.label')]: t('paragraphs.map.tipologies.circularEconomy'),
      "Tipologia impianto": "Trattamento rifiuti",
      "Tipo Produzione": "",
      "Tipologia Rifiuti": "RIFIUTI INDUSTRIALI",
      "Quantità Rifiuti Trattati [t/anno]": "12,5"
    },
    {
      "Nome Società": "",
      "Nome Impianto": "Biotech Caivano",
      "Latitudine": 40.9564440323242,
      "Longitudine": 14.3084203211063,
      "Stato": "Progetto",
      [t('paragraphs.map.tipologies.label')]: t('paragraphs.map.tipologies.circularEconomy'),
      "Tipologia impianto": "Produzione Biometano",
      "Tipo Produzione": "Biometano",
      "Tipologia Rifiuti": "FORSU",
      "Quantità Rifiuti Trattati [t/anno]": 88
    },
    {
      "Nome Società": "",
      "Nome Impianto": "Sito stoccaggio Monsano",
      "Latitudine": 43.56266,
      "Longitudine": 13.250095,
      "Stato": "In esercizio",
      [t('paragraphs.map.tipologies.label')]: t('paragraphs.map.tipologies.circularEconomy'),
      "Tipologia impianto": "Sito di Stoccaggio",
      "Tipo Produzione": "",
      "Tipologia Rifiuti": "RIFIUTI INDUSTRIALI",
      "Quantità Rifiuti Trattati [t/anno]": ""
    },
    {
      "Nome Impianto": "Apulia Hydrogen Valley",
      "Latitudine": 41.0194015169688,
      "Longitudine": 16.5625580583624,
      "Stato": "Progetto",
      [t('paragraphs.map.tipologies.label')]: t('paragraphs.map.tipologies.greenHydrogen'),
      "Produzione annua (m3)": "fino a 300 milioni",
      "Capacità di elettrolisi (MW)": 220,
      "Potenza parchi fotovoltaici (MW)": 400
    },
    {
      "Nome Impianto": "MELFI",
      "Latitudine": 41.065413,
      [t('paragraphs.map.tipologies.label')]: t('paragraphs.map.tipologies.biodiversity'),
      "Longitudine": 15.713451,
      "Descrizione breve": "L’ape è un perfetto sensore ambientale: riesce a “perlustrare” un’area di circa 7 km2 (terra, vegetazione, acqua, aria…), grazie alla raccolta di nettare, polline, melata, propoli, acqua e, involontariamente, di polveri e inquinanti di varie origini. Il biomonitoraggio degli alveari consiste nell’analisi dello stato di salute delle colonie e delle concentrazioni di composti chimici sulle matrici prodotte (miele o cera) dagli stessi. Osservando le api e i loro parametri biologici così come le variazioni ecologiche dovute all’effetto di una o più sostanze inquinanti presenti nei vari comparti della biosfera, monitoriamo e valutiamo l'eventuale impatto ambientale derivante dalle attività produttive di un sito industriale o di un territorio."
    },
    {
      "Nome Impianto": "BUSCA",
      "Latitudine": 44.516625,
      "Longitudine": 7.477825,
      [t('paragraphs.map.tipologies.label')]: t('paragraphs.map.tipologies.biodiversity'),
      "Descrizione breve": "Il territorio piemontese presenta migliaia di ettari di aree boschive: Cuneo e Torino raggruppano circa il 50% dei boschi del Piemonte​. Abbiamo raccolto 120 ettari di boschi pubblici e privati abbandonati da circa 100 anni unendoli nell'Associazione Fondiaria Terre di Mezzo per favorire la gestione attiva del territorio e la valorizzazione delle risorse locali.\nLa manutenzione dei boschi permette di tutelare e mettere in sicurezza il patrimonio boschivo e di ottenere biomassa da filiera corta per alimentare impianti di teleriscaldamento efficienti a servizio di utenze residenziali e industriali.\nCi prendiamo cura del territorio e della biodiversità e costruiamo soluzioni energetiche sostenibili alimentate da fonti rinnovabili."
    },
    {
      "Nome Impianto": "STUPINIGI",
      "Latitudine": 44.998337,
      "Longitudine": 7.608004,
      [t('paragraphs.map.tipologies.label')]: t('paragraphs.map.tipologies.biodiversity'),
      "Descrizione breve": "L'area protetta del Parco Naturale di Stupinigi che include anche la Palazzina di Caccia progettata dall'architetto Juvarra è costituita da un’estesa superficie boschiva caratterizzata da una ricca vegetazione e specie botaniche rare. Il parco è l'ambiente di vita di molte specie faunistiche come lepri e volpi e 95 specie di uccelli nidificanti tra cui la cicogna bianca. Per tutelare la biodiversità abbiamo monitorato gli effetti delle emissioni sulla vegetazione analizzando la defoliazione e l'ingiallimento delle foglie di 20 esemplari di frassino e su 5 di essi abbiamo osservato i possibili danni da ozono. Attraverso centraline alimentate a pannelli solari abbiamo monitorato parametri ambientali come CO<sub>2</sub>, O3, NO2, CO e VOC."
    },
    {
      "Nome Impianto": "Fiv - Genova",
      "Latitudine": 44.404075,
      "Longitudine": 8.948363,
      [t('paragraphs.map.tipologies.label')]: t('paragraphs.map.tipologies.culturalHeritage'),
      "Descrizione del progetto": "Edison NEXT è main-sponsor della Federazione Italiana Vela (FIV) con cui condivide valori come lo spirito di squadra, il rispetto dell’ambiente e l’innovazione. Edison NEXT ha messo a disposizione di FIV le proprie competenze per valutare la fattibilità di interventi di efficientamento energetico e decarbonizzazione del parco immobiliare federale. Inoltre Edison NEXT accompagna FIV nella promozione della Para Sailing Accademy, un progetto itinerante lungo tutta la penisola che intende avvicinare alla vela un pubblico sempre più vasto."
    },
    {
      "Nome Impianto": "Biennale di Venezia",
      "Latitudine": 45.429022,
      "Longitudine": 12.356502,
      [t('paragraphs.map.tipologies.label')]: t('paragraphs.map.tipologies.culturalHeritage'),
      "Descrizione del progetto": `Edison Next con la Fondazione Biennale di Venezia ha avviato un percorso verso la sostenibilità e l’uso efficiente delle risorse. Nel 2018 è stata realizzata una diagnosi energetica delle principali strutture della Mostra, che ha permesso di individuare, grazie all’ IoT e a evolute tecniche di modellazione, la soluzione di illuminazione ad alta efficienza individuata che è stata applicata alle Corderie dell’Arsenale preservandone l’estetica industriale originaria.<br /><br />
      Risultati:<br />
      - 70% di consumi di energia elettrica<br />
      - 22 tonnellate di CO2 emesse all’anno in atmosfera (quantità pari a quella assorbita da 660 piante)<br /><br />
      Durante le Biennali Architettura del 2018 e del 2021 sono state installate postazioni smart con punti di ricarica green e wi-fi gratuito.`
    },
    {
      "Nome Impianto": "Teatro alla Scala di Milano",
      "Latitudine": 45.467231,
      "Longitudine": 9.189657,
      [t('paragraphs.map.tipologies.label')]: t('paragraphs.map.tipologies.culturalHeritage'),
      "Descrizione del progetto": "Edison Next a partire dal 2018 ha realizzato interventi per migliorare l’efficienza del teatro e delle sedi principali della Fondazione, come l’efficientamento energetico dei Laboratori ex-Ansaldo, dei globi e del lampadario centrale che decorano la Sala “Piermarini” e del sistema di illuminazione interna dei palchi e del foyer, con una riduzione dei consumi di energia elettrica superiore all’80%. Nel 2021 è nato il progetto “Scala Green”, percorso di sostenibilità energetica e decarbonizzazione in tutte le sedi cittadine della Fondazione per ridurre l’impronta carbonica evitando l’emissione di 2.600 tonnellate di CO2 all’anno (quantità assorbita da circa 90.000 piante)"
    },
    {
      "Nome Impianto": "FAI - Cavallerizza di Milano",
      "Latitudine": 45.460864,
      "Longitudine": 9.212559,
      [t('paragraphs.map.tipologies.label')]: t('paragraphs.map.tipologies.culturalHeritage'),
      "Descrizione del progetto": "Edison Next per la sede della fondazione ha realizzato alcuni lavori di efficientamento energetico ponendo attenzione alle peculiarità del sito: al suo interno infatti è conservato l’archivio dell’emeroteca della Biblioteca Nazionale Braidense che necessita di temperatura e livelli di umidità ottimali, al fine di garantirne la conservazione nel tempo. Qui sono stati reliazzati una serie di interventi (smart audit, efficientamento termico e illuminazione, monitoraggio umidità e clima) che hanno consentito di ridurre del 40% i consumi e hanno portato ad una riduzione pari a 1,8 tonnellate all’anno delle emissioni di CO2 (quantità assorbita da circa 55 piante). "
    },
    {
      "Nome Impianto": "FAI - Villa Panza",
      "Latitudine": 45.825516,
      "Longitudine": 8.82895,
      [t('paragraphs.map.tipologies.label')]: t('paragraphs.map.tipologies.culturalHeritage'),
      "Descrizione del progetto": "Edison Next per Villa e Collezione Panza ha realizzato un relamping che ha portato ad un abbattimento dei consumi pari al 90%, evitando l’emissione in atmosfera di 13,2 tonnellate di CO2 all’anno (quantità pari a quella assorbita da circa 400 piante). In ultimo, per rendere sostenibili gli spostamenti, sono state installate colonnine di ricarica per le auto elettriche."
    },
    {
      "Nome Impianto": "FAI - Villa Panza",
      "Latitudine": 45.825516,
      "Longitudine": 8.82895,
      [t('paragraphs.map.tipologies.label')]: t('paragraphs.map.tipologies.biodiversity'),
      "Descrizione del progetto": "Presso Villa Panza verranno installate essenze nettarifere e web cam presso le arnie per offrire alle api spazi favorevoli alla sopravvivenza e al benessere."
    },
    {
      "Nome Impianto": "FAI - Villa dei Vescovi",
      "Latitudine": 45.343093,
      "Longitudine": 11.710596,
      [t('paragraphs.map.tipologies.label')]: t('paragraphs.map.tipologies.culturalHeritage'),
      "Descrizione del progetto": "Edison Next per Villa dei Vescovi ha realizzato interventi di smart audit  e sono in corso di realizzazione interventi di efficientamento energetico della centrale termica della Villa."
    },
    {
      "Nome Impianto": "FAI - Villa dei Vescovi",
      "Latitudine": 45.343093,
      "Longitudine": 11.710596,
      [t('paragraphs.map.tipologies.label')]: t('paragraphs.map.tipologies.biodiversity'),
      "Descrizione del progetto": "Presso Villa Vescovi verranno installate essenze nettarifere e web cam presso le arnie per offrire alle api spazi favorevoli alla sopravvivenza e al benessere."
    },
    {
      "Nome Impianto": "FAI - Castello e Parco di Masino",
      "Latitudine": 45.392472,
      "Longitudine": 7.959255,
      [t('paragraphs.map.tipologies.label')]: t('paragraphs.map.tipologies.culturalHeritage'),
      "Descrizione del progetto": "Edison Next per il Castello di Masino ha realizzato interventi di smart audit e in ambito mobilità elettrica con l'installazione di 1 colonnina elettrica."
    },
    {
      "Nome Impianto": "FAI - Castello e Parco di Masino",
      "Latitudine": 45.392472,
      "Longitudine": 7.959255,
      [t('paragraphs.map.tipologies.label')]: t('paragraphs.map.tipologies.biodiversity'),
      "Descrizione del progetto": "Presso il Castello di Masino verranno installate essenze nettarifere e web cam presso le arnie per offrire alle api spazi favorevoli alla sopravvivenza e al benessere e verrà effettuato il ripristino delle rondonaie e installazione di richiami."
    },
    {
      "Nome Impianto": "FAI - Villa Necchi Campiglio",
      "Latitudine": 45.469015,
      "Longitudine": 9.202032,
      [t('paragraphs.map.tipologies.label')]: t('paragraphs.map.tipologies.culturalHeritage'),
      "Descrizione del progetto": "Edison Next per Villa Necchi Campiglio ha realizzato interventi di smart audit e stiamo lavorando all'efficientamento energetico dell'impianto termico della struttura."
    }
  ]

  const customMapStyles = [
    {
      "featureType": "administrative",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#595959"
        }
      ]
    },
    {
      "featureType": "administrative",
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "visibility": "on"
        }
      ]
    },
    {
      "featureType": "administrative.country",
      "elementType": "all",
      "stylers": [
        {
          "visibility": "simplified"
        }
      ]
    },
    {
      "featureType": "administrative.country",
      "elementType": "geometry",
      "stylers": [
        {
          "visibility": "on"
        },
        {
          "color": "#90b6d3"
        }
      ]
    },
    {
      "featureType": "administrative.country",
      "elementType": "labels",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "administrative.province",
      "elementType": "all",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "administrative.locality",
      "elementType": "all",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "administrative.locality",
      "elementType": "labels.text",
      "stylers": [
        {
          "color": "#565656"
        }
      ]
    },
    {
      "featureType": "landscape",
      "elementType": "all",
      "stylers": [
        {
          "color": "#f4f4f4"
        },
        {
          "visibility": "on"
        }
      ]
    },
    {
      "featureType": "landscape.man_made",
      "elementType": "all",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "landscape.natural",
      "elementType": "all",
      "stylers": [
        {
          "visibility": "on"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "all",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "all",
      "stylers": [
        {
          "saturation": -100
        },
        {
          "lightness": 45
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "all",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "all",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "transit",
      "elementType": "all",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "all",
      "stylers": [
        {
          "color": "#b8d6ec"
        },
        {
          "visibility": "on"
        }
      ]
    }
  ]

  const mapStyles = {
    height: size.width < 768 ? "100vh" : "calc(100vh - 160px)",
    width: "100%"
  };

  const defaultCenter = {
    lat: 44.498955, lng: 11.327591
  }

  const [currentFilter, setCurrentFilter] = useState<any>();

  useEffect(() => {
    document.querySelector("html")?.style.removeProperty("scroll-behavior");
  }, []);

  useEffect(() => {

    const _locations = pins.map((p, i) => {
      return {
        name: p['Nome Impianto'],
        category: p[t('paragraphs.map.tipologies.label')],
        key: p['Nome Impianto'] + i,
        location: {
          lat: p['Latitudine'],
          lng: p['Longitudine']
        },
        meta: p
      }
    });

    let typologies = [];
    pins.forEach(p => typologies = [...typologies, p[t('paragraphs.map.tipologies.label')]]);

    setFilterTypologies(typologies.filter(function (item, pos) {
      return typologies.indexOf(item) == pos;
    }));

    setLocations(_locations)
    setBaseLocations(_locations)
  }, [])

  const [markerCoords, setMarkerCoords] = useState<any>();

  useEffect(() => {
    if (currentFilter && currentFilter !== "Tipologia di impianto") {
      const _locations = baseLocations.filter(fi => {
        return fi.category === currentFilter
      })
      setLocations(_locations)
    } else {
      setLocations(baseLocations)
    }
  }, [currentFilter, baseLocations])

  useEffect(() => {
    setCurrentMarker(null);
    setMarkerCoords(null)
  }, [locations])


  useEffect(() => {

    if (currentMarker) {
      setShowModal(true)
    } else {
      setShowModal(false)
    }
  }, [currentMarker])



  const boxContainerRef = useRef(null);
  const boxRef = useRef(null);
  useEffect(() => {
    const boxContainer = boxContainerRef.current;
    const box = boxRef.current;

    ScrollTrigger.normalizeScroll({ target: styles.overflowWrap });

    let triggers = ScrollTrigger.getAll();

    if (size && size?.width >= 1024) {
      gsap.to(box, {
        yPercent: -100,
        ease: "none",
        scrollTrigger: {
          refreshPriority: 3,
          trigger: boxContainer,
          pin: true,
          start: "top top",
          pinSpacing: true,
          scrub: 1,
          // markers: true,
          end: () =>  "+=" + (box.offsetHeight),
          onLeave: handleClose,
          onLeaveBack: handleClose,
        }
      });
    } else if (size && size?.width < 1024) {
      triggers.forEach(trigger => {
        trigger.kill();
      });
    }
    triggers.forEach(trigger => {
      trigger.refresh();
    });
    ScrollTrigger.refresh();

    addEventListener('scroll', (event) => {
      if (window.scrollY > boxContainer.parentNode.offsetTop - 10 && window.scrollY < boxContainer.parentNode.offsetTop) {
        triggers.forEach(trigger => {
          trigger.refresh();
        });
        ScrollTrigger.refresh();
      }
    });

  }, [size]);

  const handleClose = () => {
    setCurrentMarker(false)
  }

  return (

    <div>
      <Dialog open={showModal} marker={currentMarker} handleClose={handleClose} />

      <div ref={boxContainerRef} className={styles.boxContainer}>
        <Grid container spacing={2}>
          <Grid item lg={6} xs={12}>
            <div className={styles.overlayFilterContainer}>
              <div className={styles.overlayFilter}>
                <div className={styles.filterBy}>
                  <p>{t('paragraphs.map.filter.label')}:</p>
                </div>
                <div className={styles.filter}>
                  <FormControl variant="standard" sx={{ padding: "5px", minWidth: 120, width: "100%" }}>
                    <Select
                      onChange={(e: any) => setCurrentFilter(e.target.value)}
                      label={currentFilter}
                      className={styles.customSelect}
                    >
                      <MenuItem value="" className={styles.customItem}>
                        <span className={styles.menuitem}>{t('paragraphs.map.filter.viewAll')}</span>
                      </MenuItem>

                      {filterTypologies.map((fi, ind) => {
                        return <MenuItem key={ind} className={styles.customItem} value={fi}>
                          <div style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center"
                          }} ><div style={{
                            background: colorMap[fi]
                          }} className={styles.dot}></div><span className={styles.menuitem}>{fi}</span> </div>
                        </MenuItem>
                      })}

                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>

            <div id="mapC">
              <LoadScriptNext
                googleMapsApiKey={process.env.NEXT_PUBLIC_GMAPS_API_KEY}>
                <GoogleMap
                  onLoad={(e: any) => setMap(e)}
                  mapContainerStyle={mapStyles}
                  zoom={5}
                  onZoomChanged={function () {
                    setZoom(map?.getZoom())
                  }}
                  options={{
                    styles: customMapStyles,
                    disableDefaultUI: true
                  }}
									onUnmount={e => { }}
                  center={defaultCenter}
                >
                  {
                    locations.map(item => {
                      return (
                        <OverlayView
                          key={item.key} position={item.location} mapPaneName={"floatPane"} >
                          <button
                            style={{
                              transform: "translateZ(0) translate(-50%, -80%)"
                            }}
                            onMouseOver={() => {
                              setMarkerCoords(item.location)
                              setMarkerInfo(item);
                            }} className={styles.noButton} onClick={() => {
                              onMarkerClick(item)
                            }}>
                            <MarkerPin fill={
                              colorMap[item.category]
                            } />
                          </button>
                        </OverlayView>

                      )
                    })
                  }
                  {markerCoords && <InfoWindow zIndex={999} onCloseClick={() => setMarkerCoords(null)} position={markerCoords}>
                    <>
                      <div style={{
                        "cursor": "pointer"
                      }} onClick={() => onMarkerClick(markerInfo)} className={styles.infoWindow} >
                        <p className={styles.infoWindowTitle}>{markerInfo?.name}</p>
                      </div>
                    </>
                  </InfoWindow>}
                </GoogleMap>
              </LoadScriptNext>
            </div>
          </Grid>
          {size && size?.width >= 1024 && <Grid item lg={6} xs={12} style={{
            paddingRight: "1.125rem"
          }}>
            <Box className={styles.mapTextContainer}>
              <div ref={boxRef}
                dangerouslySetInnerHTML={{ __html: field_map_content?.processed }}></div>
              <div className={styles.mapTextContainerOverlay}></div>
            </Box>
          </Grid>}
        </Grid>
      </div>

    </div>
  )
}
