import Image from "next/legacy/image";
import { FC, useMemo } from "react";


import Row from "@components/Modules/Utils/Row/Row";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import Box from "@mui/material/Box";

import { getThumbnailAlt, getThumbnailUrl } from "@helper/media";

import FadeIn from "@components/Common/FadeIn/FadeIn";
import { ParagraphModel, ParagraphProps } from "@model/paragraphs.model";
import { Link } from "@mui/material";
import styles from './NewsContent.module.scss';

interface NewsContentProps {
  field_newscontent_background: string,
  field_newscontent_items: ParagraphModel[],
}

const NewsContent: FC<ParagraphProps<NewsContentProps>> = ({ fieldData, index, node }) => {

  const { field_newscontent_background, field_newscontent_items } = fieldData;

  const componentItem = {
    text: NewsContentText,
    'text_image': NewsContentTextImage,
    image: NewsContentImage,
  }

  const isFirstContent: boolean = node?.field_paragraph[0].type == 'paragraph--hero' && index === 1;
  
  return (
    <FadeIn>
      <Box component="section" bgcolor={field_newscontent_background} className={styles.NewsContent} marginTop={{lg: isFirstContent ? '16vh' : 0}}>
        <Container maxWidth={false}>

          {!!field_newscontent_items?.length && field_newscontent_items.map((el: ParagraphModel) => {
            const type: string = el.type.replace('paragraph--news_content_item_', '')
            const Component = componentItem[type] || null;

            return <Component key={el.id} data={el}/>;
          })}

        </Container>
      </Box>
    </FadeIn>
  )
}


const NewsContentText: React.FC<any> = ({ data }) => {

  const pt: number = data?.field_newscontent_margintop ? data.field_newscontent_margintop : 0
  const pb: number = data?.field_newscontent_marginbottom ? data.field_newscontent_marginbottom : 0
  const column: number = data?.field_newscontent_column ? data.field_newscontent_column : 12

  return (
    <Row className={styles.item} pt={pt} pb={pb} justifyContent="center">
      <Grid item xs={12} lg={column}>
        {data.field_newscontent_text_separator && <div className={styles.separator} />}
        {data.field_newscontent_text_content?.processed && <Typography variant="bodyL" component="div" dangerouslySetInnerHTML={{ __html: data.field_newscontent_text_content?.processed }} className={styles.textContent} />}
      </Grid>
    </Row>
  )
}

const NewsContentTextImage: React.FC<any> = ({ data }) => {
  const pt: number = data?.field_newscontent_margintop ? data.field_newscontent_margintop : 0
  const pb: number = data?.field_newscontent_marginbottom ? data.field_newscontent_marginbottom : 0

  const imageUrl: string | null = data?.field_newscontent_img ? getThumbnailUrl(data.field_newscontent_img) : null;
  const imageAlt: string | null = data?.field_newscontent_img ? getThumbnailAlt(data?.field_newscontent_img) : null;
  const width: number = data?.field_newscontent_img?.field_media_image?.resourceIdObjMeta?.width;
  const height: number = data?.field_newscontent_img?.field_media_image?.resourceIdObjMeta?.height;

  const reverseColumn: string = data?.field_newscontent_img_pos_dt
  const reverseColumnMob: string = data?.field_newscontent_img_pos_mob

  return (
    <Row className={styles.item} pt={pt} pb={pb} sx={{
      flexDirection: {
        xs: reverseColumnMob == 'top' ? 'column' : 'column-reverse',
        lg: reverseColumn == 'left' ? 'row' : 'row-reverse'
      }
    }}>

      <Grid item xs={12} lg={5}>
        {data?.field_newscontent_img_title && <Typography variant="bodyM" component="div" dangerouslySetInnerHTML={{ __html: data?.field_newscontent_img_title?.processed }} className={`${styles.imgTitle}}`} />}
        {imageUrl && !data?.field_svg_img &&
          <Image src={imageUrl} alt={imageAlt} width={width} height={height} />
        }
        {data?.field_svg_img && !imageUrl &&
          <div
          dangerouslySetInnerHTML={{ __html: data?.field_svg_img?.processed }} className={styles.svgImg} 
          />
        }
        {data?.field_newscontent_img_note && <Typography variant="bodyS" component="div" sx={{ textAlign: reverseColumn }} dangerouslySetInnerHTML={{ __html: data?.field_newscontent_img_note?.processed }} className={`${styles.imgNote}}`} />}
      </Grid>
      <Grid item lg={1} sx={{ display: { xs: 'none', lg: 'flex' } }} />
      {data.field_newscontent_text_content &&
        <Grid item xs={12} lg={6}>
          {data?.field_newscontent_text_separator && <div className={styles.separator} />}
          {data?.field_newscontent_text_content?.processed && <Typography variant="bodyL" component="div" dangerouslySetInnerHTML={{ __html: data?.field_newscontent_text_content?.processed }} className={styles.textContent} />}
        </Grid>
      }

    </Row>
  )
}

const NewsContentImage: React.FC<any> = ({ data }) => {

  const pt: number = data?.field_newscontent_margintop ? data.field_newscontent_margintop : 0
  const pb: number = data?.field_newscontent_marginbottom ? data.field_newscontent_marginbottom : 0
  const column: number = data?.field_newscontent_column ? data.field_newscontent_column : 12

  const imageUrl: string | null = data?.field_newscontent_img ? getThumbnailUrl(data.field_newscontent_img) : null;
  const imageAlt: string | null = data?.field_newscontent_img ? getThumbnailAlt(data?.field_newscontent_img) : null;
  const width: number = data?.field_newscontent_img?.field_media_image?.resourceIdObjMeta?.width;
  const height: number = data?.field_newscontent_img?.field_media_image?.resourceIdObjMeta?.height;
  const fieldImageLink = data?.field_image_link?.url || '';
  
  const imageWithLink = useMemo(() => {
    if (!fieldImageLink) return null;
    return (
      <Link 
        sx={{
          display: 'inline-flex',
          height: '100%',
          width: '100%'
        }} 
        href={fieldImageLink}
      >
        <Image
          src={imageUrl}
          alt={imageAlt}
          width={width}
          height={height}
        />
      </Link>
    );
  }, [fieldImageLink, height, imageAlt, imageUrl, width]);

  return (
    <Row className={styles.item} pt={pt} pb={pb}>
      <Grid item xs={12} lg={column}>
        {
          imageWithLink 
            ? imageWithLink 
            : <>
                <Typography variant="bodyM" component="div" dangerouslySetInnerHTML={{ __html: data?.field_newscontent_img_title?.processed }} className={`${styles.imgTitle}}`} />
                {imageUrl && <Image src={imageUrl} alt={imageAlt} width={width} height={height} />}
            </>
        }
        
        <Typography variant="bodyS" component="div" sx={{
          textAlign: data?.field_newscontent_img_pos_dt
        }} dangerouslySetInnerHTML={{ __html: data?.field_newscontent_img_note?.processed }} className={`${styles.imgNote}}`} />
      </Grid>
    </Row>
  )
}

export default NewsContent