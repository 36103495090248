import React from "react";

function MarkerPin({fill = "#001A70"}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="38"
      fill="none"
      viewBox="0 0 29 38"
    >
      <path
        fill={fill}
        fillRule="evenodd"
        d="M14.09 37.094l.004.003a1.274 1.274 0 001.48-.001l.003-.002.01-.007.032-.024.118-.087c.102-.075.248-.185.432-.328.368-.286.888-.704 1.51-1.242a43.522 43.522 0 004.566-4.579C25.538 26.976 29 21.437 29 15.072c0-3.82-1.492-7.485-4.15-10.186A14.05 14.05 0 0014.834.666c-3.757 0-7.36 1.518-10.017 4.22a14.529 14.529 0 00-4.15 10.186c0 6.364 3.463 11.904 6.756 15.755a43.535 43.535 0 004.565 4.579 37.851 37.851 0 001.942 1.57l.119.087.033.024.009.007zm.743-16.427a5.833 5.833 0 100-11.667 5.833 5.833 0 000 11.667z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default MarkerPin;