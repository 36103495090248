
import { useTranslation } from "next-i18next";

import {
  DrupalNode
} from "next-drupal";

import ArticleCardCarousel from "@components/Modules/NextJournal/ArticleCardCarousel/ArticleCardCarousel";
import ArticleCardGrid from "@components/Modules/NextJournal/ArticleCardGrid/ArticleCardGrid";
import ContentHeader from "@components/Modules/NextJournal/ContentHeader/ContentHeader";
import { East } from "@mui/icons-material";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import useTheme from "@mui/system/useTheme";
import { FC } from "react";
import { ArticleCardVariant } from "../ArticleCard";
import { isFocusCategory } from "../constants";

export interface CategoryCardsSectionProps { 
  url: string;
  name: string;
  articles: DrupalNode[],
  i?: number;
};

const CategoryCardsSection: FC<CategoryCardsSectionProps> = ({ url, name, articles, i }) => {
  const { t } = useTranslation();
  const isMobile: boolean = useMediaQuery(useTheme().breakpoints.down("lg"));
  
  const cardVariant: ArticleCardVariant = isFocusCategory(name) ? 'complete': 'default'
  return (
    <>
      {articles?.length ? (
        <Box>
          <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', pt: { xs: 20, lg: i === 0 ? "16vh" : 0 }  }}>
            <Box>
              <ContentHeader isMobile={isMobile} title={name} />
            </Box>

            {!isMobile ? 
            // TODO: da fixare con next-link, vedi useJournalSlugArticles, non aggiorna dati al cambio di router.asPath
              <a href={url || '/next-journal'} >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                  }}
                >
                  <East sx={{ mr: "1rem" }} />
                  <Typography sx={{ color: "secondary" }} >
                    {t("next-journal.index.readAll")}
                  </Typography>
                </Box>
              </a> : null
            }
          </Box>

          {isMobile ? <ArticleCardCarousel cardVariant={cardVariant} articles={articles} /> : <ArticleCardGrid cardVariant={cardVariant} articles={articles} /> }
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};

export default CategoryCardsSection;
