import { useEffect, useRef, useState } from 'react';
import Image from "next/legacy/image";

import {getThumbnailAlt, getThumbnailUrl} from "@helper/media";
import useOnScreen from '@hooks/useOnScreen';

import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Fade from "@mui/material/Fade";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import Row from '@components/Modules/Utils/Row/Row';
import EastIcon from '@mui/icons-material/East';
import * as Icons from '../../UI/CustomIcons/index';

import styles from './Obbiettivi.module.scss'
import { useTranslation } from 'next-i18next';
import Box from '@mui/material/Box';

interface FieldData {
  fieldData: {
    field_obbiettivi_background?: string;
    field_obbiettivi_content?: any;
    field_obbiettivi_theme?: string;
    field_obbiettivi_title?: string;
    field_obbiettivi_item?: any;
    field_obbiettivi_overlay?: any;
    field_obiettivi_copyright?: any;
    behavior_settings: {
      style_options: {
        obbiettivi_layout: {
          better_css_class: "flex" | "layout-2"
        }
      }
    }
  };
  id: string;
}

interface FieldObbiettivo {
  data: any;
  light: boolean;
  colSize: 3 | 6 | 9 | 12;
}

export default function Obbiettivi({ fieldData }: FieldData) {
  const ref: any = useRef(null);
  const onScreen: boolean = useOnScreen(ref);
  const [loaded, setLoadead] = useState(false);

  const {field_obbiettivi_background, field_obbiettivi_content, field_obbiettivi_theme, field_obbiettivi_overlay, field_obbiettivi_title, field_obbiettivi_item, field_obiettivi_copyright, behavior_settings } = fieldData

  const layout = behavior_settings?.style_options?.obbiettivi_layout?.better_css_class || 'flex'

  useEffect(()=> {
    if(onScreen) setLoadead(true);
  }, [onScreen])

  const background = field_obbiettivi_background && getThumbnailUrl(field_obbiettivi_background);
  const themeLight = field_obbiettivi_theme;
  const overlay = field_obbiettivi_overlay;
  const title = field_obbiettivi_title;
  const content = field_obbiettivi_content?.value;

  const colSize = layout == 'layout-2' ? 6 : 3

  return (
    <Fade ref={ref} in={loaded} appear={false} timeout={1000} style={{transitionDelay: '100ms'}}>
      <Container
        maxWidth={false}
        className={`paragraph_obbiettivi ${styles.obiettiviWrapper} ${background && styles.background} ${themeLight ? styles.themeLight : styles.themeDark} ${background ? 'paragraph_obbiettivi_background' : ''} ${overlay ? 'paragraph_obbiettivi_overlay' : ''}`}
      >
        {overlay ? <div className={`${styles.overlay} ${background ? styles.overlayBg : ''}`} /> : null}
        {background && 
          <Image 
            src={getThumbnailUrl(field_obbiettivi_background)}
            alt={getThumbnailAlt(field_obbiettivi_background)}
            layout='fill'
            objectFit="cover"
            style={{zIndex: '-1'}}
          />
        }

        {(title || content) &&
          <Row justifyContent={'center'} sx={{marginBottom: {xs: 92, lg: 64}}}>
            <Grid className={styles.content} item lg={9} textAlign="center">
              {title ? <Typography variant="h2" component="h2" mb={24} className={styles.title}>{title}</Typography> : <div className={styles.title}></div>}
              {content && <Typography variant="bodyL" component="div" className={styles.desc} dangerouslySetInnerHTML={{ __html: content }} />}
            </Grid>
          </Row>
        }

        <Row rowSpacing={{xs: 64}} columnSpacing={{lg: 28}} justifyContent={'center'}>
          {field_obbiettivi_item?.map((el, i) => <Obbiettivo
            key={el.id}
            data={el}
            light={!!themeLight}
            colSize={layout == 'layout-2' && i == 0 ? 12 : colSize}
          />)}
        </Row>

        {field_obiettivi_copyright?.value && <Typography
                      className={styles.copyright}
                      dangerouslySetInnerHTML={{ __html: field_obiettivi_copyright?.value }}
                      style={{ display: "block" }}
                      variant="bodyL"
                      component="div"
                    />    }

      </Container>
    </Fade>
  )
}

function Obbiettivo({data: {id, field_obbiettivi_item_icon : iconName, field_obbiettivi_item_number : number, field_obbiettivi_item_title : title, field_obbiettivi_item_content : content, field_obbiettivi_item_cta}, light, colSize}: FieldObbiettivo) {
  const {t} = useTranslation();
  const Icon = iconName ? Icons[iconName?.name?.replace(/\.[^/.]+$/, "")] : null;
  const ctaUrl = field_obbiettivi_item_cta?.url;
  const ctaText = field_obbiettivi_item_cta?.title ? field_obbiettivi_item_cta.title : t("discoverMore");

  let urlExternal = field_obbiettivi_item_cta && !field_obbiettivi_item_cta.url.startsWith('/') && !field_obbiettivi_item_cta.url.includes(process.env.NEXT_PUBLIC_BASE_URL);

  return (
    <>
      <Grid className={styles.item} item xs={12} lg={colSize} textAlign="center">
        {Icon &&
          <Box className={styles.itemIcon}>
            {!number && <Icon color={light && '#ffffff'} />}
            
            {number && (
              <>
                <Icon color={light && 'rgba(255,255,255,0.2)'} />
                <Typography variant="h1" component="div" className={styles.itemNumber} dangerouslySetInnerHTML={{__html: number}}/>
              </>
            )}
          </Box>
        }
        {title && <Typography variant="subtitle" component="p" className={styles.itemTitle} dangerouslySetInnerHTML={{__html: title}} />}
        {content && <Typography variant="bodyL" component="div" fontWeight={'light'} className={styles.itemDesc} marginBottom={32} dangerouslySetInnerHTML={{__html: content.processed}} />}
        {ctaUrl && (
          <Button
            id={id}
            variant="text"
            color={light ? 'secondary' : 'primary'}
            href={ctaUrl}
            target={urlExternal ? "_blank" : "_self"}
            size="small"
            className={styles.itemCta}
            startIcon={<EastIcon />}
          >{ctaText}</Button>
        )}

      </Grid>
    </>
  )
}
