import { useEffect, useMemo, useRef, useState } from "react";
import styles from "./Slider.module.scss";
import { SliderProps } from "./types";
import useDeviceDetect from "@lib/deviceDetect";
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

// @TODO: refactor

type Params = {
  size: {  width: number | undefined;
    height: number | undefined;}
} & Pick<SliderProps, 'field_slider_item' | 'field_slider_layout'>

gsap.registerPlugin(ScrollTrigger);

const useSectionSlider = ({size, field_slider_layout, field_slider_item}: Params) => {
  const [scrolled, setScrolled] = useState(false);

  const { isMobileAgent } = useDeviceDetect();
  const revealRefs = useRef([]);
  revealRefs.current = [];

  const addToRefs = (el) => {
    if (el && !revealRefs.current.includes(el)) {
      revealRefs.current.push(el);
    }
  };

  const panelsRef = useRef(null);

  useGSAP( () => {
    const panels = gsap.utils.toArray(revealRefs.current);
    const panelsContainer = panelsRef.current;
    const numSections = panels.length - 1;
    // const snapVal = 1 / numSections;

    if (size && size?.width >= 1024)
      ScrollTrigger.normalizeScroll({ target: styles.overflowWrap });

    let triggers: ScrollTrigger[] = ScrollTrigger.getAll();

    if (size && size?.width >= 1024) {
      gsap.to(panels, {
        xPercent: -100 * numSections,
        ease: "none",
        scrollTrigger: {
          refreshPriority: 3,
          trigger: panelsContainer,
          pin: true,
          start: "center center",
          pinSpacing: true,
          scrub: 1,
          end: () => "+=" + (panelsContainer.offsetWidth - innerWidth),
        },
      });

      triggers.forEach((trigger) => {
        trigger.refresh();
      });
    } else if (size && size?.width < 1024) {
      triggers.forEach((trigger) => {
        trigger.kill();
      });
    }

    const iframes = document?.querySelectorAll("iframe");

    if (!isMobileAgent && iframes.length)
      iframes.forEach(function (iframe) {
        iframe.removeAttribute("allowfullscreen");
        iframe.setAttribute("fs", "0");
        iframe.setAttribute("frameborder", "0");
      });

    const panelsQ = document.querySelectorAll<HTMLElement>(
      '[class^="Slider_panel"]'
    );
    if (window.innerWidth < 1024) {
      triggers.forEach((trigger) => {
        trigger.kill();
      });

      panelsQ.forEach((panel) => {
        if (!panel) return;
        panel.style.transform = "none";
      });
    }
  }, [isMobileAgent, size]);

  useEffect(() => {
    const scrollHandler = () => {
      if (window?.innerWidth < 1024 || window?.scrollY <= 550) return;
      setScrolled(true);
    };
    addEventListener("scroll", scrollHandler);

    return () => {
      removeEventListener("scroll", scrollHandler);
    };
  }, [size]);

  useEffect(() => {
    if (!scrolled) return;
    const triggers = ScrollTrigger.getAll();
    triggers?.forEach((trigger) => {
      trigger.refresh();
    });
  }, [scrolled])

  const panelW: number = field_slider_layout == "layout-2" ? 25 : 60;
  const padVal: number = field_slider_layout == "layout-2" ? 16.666 : 16.666;

  const sliderSectionInlineStyle = useMemo(() => ({
    width: `calc(${field_slider_item?.length * panelW}vw + (${
      field_slider_item?.length * 1.5
    }rem + ${padVal}vw)) !important`,
  }), [field_slider_item?.length, padVal, panelW]);

  return {sliderSectionInlineStyle, addToRefs, panelsRef, }
};

export default useSectionSlider;