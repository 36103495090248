import { useEffect, useRef, useState } from "react";
import { useTranslation } from "next-i18next";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container"
import Drawer from "@mui/material/Drawer";
import Fade from "@mui/material/Fade";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";

import * as Icons from '../../UI/CustomIcons/index';

import Row from "@components/Modules/Utils/Row/Row";

import useOnScreen from "@hooks/useOnScreen";

import styles from './ModelloOperativo.module.scss'

interface FieldData {
  fieldData: any;
}

export default function ModelloOperativo({ fieldData: { field_modello_item } }: FieldData) {
  const ref: any = useRef(null);
  const onScreen: boolean = useOnScreen(ref);

  const [loaded, setLoadead] = useState(false);

  useEffect(()=> {
    if(onScreen) setLoadead(true);
  }, [onScreen])


  if (!Array.isArray(field_modello_item) || field_modello_item?.length <= 0) return <></>;

  return (
    <Fade ref={ref} in={loaded} appear={false} timeout={1000} style={{transitionDelay: '100ms'}}>
      <div className={styles.modelloOperativo}>
        {field_modello_item.map((el, i) =>  <ModelloOperativoTab key={i} el={el} index={i} /> )}
      </div>
    </Fade>
  )
}

const ModelloOperativoTab = ({ el, index }) => {
  const { t } = useTranslation();
  const Icon = el.field_modello_item_icon ? Icons[el.field_modello_item_icon?.name?.replace(/\.[^/.]+$/, "")] : null;
  const [open, setOpen] = useState(false)
  const color = ['#1E3591', '#2E5EA6', '#6096DC'];

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  return (
    <>
      <div className={styles.modelloItem} style={{ backgroundColor: color[index] }}>
        <div className={styles.modelloContent}>
          {Icon && <Icon />}
          <Typography className={styles.title} variant="h4">{el.field_modello_item_title}</Typography>
          <Typography className={styles.desc} variant="bodyL" dangerouslySetInnerHTML={{ __html: el.field_modello_item_description }} />
          <Button
            variant="text"
            color={'secondary'}
            size="small"
            className={styles.itemCta}
            startIcon={<AddIcon />}
            onClick={handleDrawerToggle}
          >{t('paragraphs.modelloOperativo.cta')}</Button>
        </div>
      </div>

      <Drawer
        PaperProps={{ className: styles.paperOperativo }}
        open={open}
        ModalProps={{ keepMounted: false }}
        onClose={handleDrawerToggle}
        SlideProps={{
          direction: 'up'
        }}
        sx={{
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '100%', backgroundColor: color[index], display: "flex", justifyContent: "center", alignItems: "center" }
        }}
      >
        <Box className={styles.box} display="flex" justifyContent="center" alignItems="center">
          <Container maxWidth={false}>
            <Row spacing={0} justifyContent={'center'}>
              <Grid item sm={8}>
                <div className={styles.titleCont}>
                  {Icon && <Icon />}
                  <Typography className={styles.title} variant="h4">{el.field_modello_item_title}</Typography>
                </div>
                <Typography className={styles.desc} variant="bodyL" dangerouslySetInnerHTML={{ __html: el.field_modello_item_content?.processed }} />
              </Grid>
            </Row>
          </Container>
        </Box>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="end"
          onClick={handleDrawerToggle}
          className={styles.closeButton}
        >
          <CloseIcon />
        </IconButton>
      </Drawer>
    </>
  )
}