import { useEffect, useState } from "react";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';

import useWindowSize from '@hooks/useWindowSize';
import styles from './Map.module.scss';

const colorMap = {
	"Teleriscaldamento": "#001A70",
	"Economia circolare": "#0E6814",
	"Idrogeno verde": "#6095DB",
	"Biodiversità": "#1057C8",
	"Patrimonio culturale": "#4E9E30",
}

export default function Dialog({ handleClose, open, marker }) {
	const size = useWindowSize();

	const [s, setS] = useState<any>();

	useEffect(() => {
		setS(size)
	}, [size])


	const boxStyles = {
		position: 'absolute' as 'absolute',
		top: s?.width < 768 ? '66px' : '50%',
		left: s?.width < 768 ? "50%" : '25%',
		transform: s?.width < 768 ? 'translate(-50%, -0%)' : 'translate(-50%, -50%)',
		width: s?.width < 768 ? s.width : 496,
		height: s?.width < 768 ? (s?.height - 60) + "px" : "auto",
		bgcolor: '#fff',
		justifyContent: "center",
		alignItems: "center",
		boxShadow: s?.width < 768 ? 0 : 24,
	}
	return <>

		<Modal
			open={open}
			onClose={handleClose}
			hideBackdrop={true}
		>
			<Box sx={boxStyles}>
				<Grid container>
					<Grid item xs={12}>
						<Button variant="link" onClick={handleClose} className={styles.closeModalButton}>
							<CloseIcon />
						</Button>
					</Grid>

					{marker && <Grid item className={styles.modalTextContainer} lg={12}>
						<p style={{ color: colorMap[marker.category]
						}} className={styles.modalTextCategory}>{marker.category}</p>
						<h4 style={{ color: colorMap[marker.category]
						}} className={styles.modalCategoryTitle}>{marker.name}</h4>
						<Grid item className={styles.modalContent}>

							{Object.keys(marker.meta).filter(k => !['Nome Impianto', 'Latitudine', 'Longitudine'].includes(k) && marker.meta[k]).map((meta, i) => {
								return (
                  <p key={'meta-' + i}>
                    <strong>{meta}:</strong>&nbsp;<span dangerouslySetInnerHTML={{ __html: marker.meta[meta] }}
                    ></span>
                  </p>
                )
							})}

						</Grid>
					</Grid>}
				</Grid>
			</Box>
		</Modal>
	</>
}
