
import { useState, useRef, useEffect } from 'react';
import Image from 'next/image';
import { NextRouter, useRouter } from 'next/router';

import { getThumbnailUrl, getVideoUrl } from '@helper/media';
import useOnScreen from "@hooks/useOnScreen";
import useWindowSize from '@hooks/useWindowSize';

import Container from '@mui/material/Container';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import Row from '@components/Modules/Utils/Row/Row';
import Play from '@components/UI/CustomIcons/Play';

import styles from './Video.module.scss';

interface FieldData {
    fieldData: any;
    field_video: any;
    field_video_preview: any;
    field_video_title?: any;
    field_video_subtitle?: any;
}

export default function Video({ fieldData: { field_video_title, field_video, field_video_preview } }: FieldData) {
    const router: NextRouter = useRouter();
    const size = useWindowSize();
    const [showCover, setShowCover] = useState(field_video_preview != undefined);
    const refImg: any = useRef(null);
    const refVideo: any = useRef(null);
    const refGrid: any = useRef(null);
    const ref: any = useRef(null);
    const onScreen: boolean = useOnScreen(ref);
    const [loaded, setLoadead] = useState(false);

    useEffect(()=> {
        setShowCover(true)
    }, [router.asPath])
    useEffect(()=> {
        if(onScreen) setLoadead(true);
    }, [onScreen])

    useEffect(() => {
        if (!refGrid.current) return;
        refGrid.current.style.minHeight = refVideo.current ? refVideo.current.clientHeight + 'px' : refImg.current.clientHeight + 'px';
    }, [size])

    return (
        <Fade ref={ref} in={loaded} appear={false} timeout={1000} style={{ transitionDelay: '100ms' }}>
            <Container disableGutters maxWidth={false} className={styles.videoPlayer}>
                <Row spacing={0} justifyContent={'center'}>
                    <Grid ref={refGrid} item sm={12}>
                        {((showCover && field_video_preview) ?
                            <div ref={refImg}>
                                <Image
                                    className={styles.preview}
                                    alt={field_video_title ? field_video_title : 'Video placeholder'}
                                    src={getThumbnailUrl(field_video_preview)}
                                    width={field_video_preview?.field_media_image?.resourceIdObjMeta?.width}
                                    height={field_video_preview?.field_media_image?.resourceIdObjMeta?.height}
                                />
                                <span className={styles.btnPlay} onClick={() => setShowCover(false)}>
                                    <Play />
                                </span>
                            </div>
                            :
                            <div>
                                <video ref={refVideo}
                                    className={styles.video}
                                    autoPlay={true}
                                    controls
                                    controlsList="nodownload"
                                    playsInline
                                >
                                    <source src={getVideoUrl(field_video)} type="video/mp4" />
                                </video>
                            </div>
                            )}
                    </Grid>
                </Row>
            </Container>
        </Fade>
    )

}