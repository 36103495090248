import { useEffect, useRef, useState } from 'react';
import useOnScreen from '@hooks/useOnScreen';

import Container from '@mui/material/Container';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Row from "@components/Modules/Utils/Row/Row";

import styles from './Text.module.scss'

interface FieldData {
  fieldData: any;
  field_text_title: string;
  field_text_content: any;
}

export default function Text({ fieldData: {field_text_title, field_text_content} }: FieldData) {
  const ref: any = useRef(null);
  const onScreen: boolean = useOnScreen(ref);

  const [loaded, setLoadead] = useState(false);

  useEffect(()=> {
    if(onScreen) setLoadead(true);
  }, [onScreen])
  
  return (
    <Fade ref={ref} in={loaded} appear={false} timeout={1000} style={{transitionDelay: '100ms'}}>
      <Container maxWidth={false} className={styles.text}>
        <Row justifyContent="center">
          <Grid item xs={12} lg={10}>
            {field_text_title && <Typography variant="h1" className={styles.textTitle}>{field_text_title}</Typography>}
            {field_text_content?.processed && <Typography variant="bodyL" component="div" dangerouslySetInnerHTML={{ __html: field_text_content?.processed }} className={styles.textContent}/>}
          </Grid>

        </Row>
      </Container>
    </Fade>
  )
}