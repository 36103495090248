import React from "react";

export default function useDeviceDetect() {
  const [isMobileAgent, setMobileAgent] = React.useState(false);

  React.useEffect(() => {
    const userAgent =
      typeof window.navigator === "undefined" ? "" : navigator.userAgent;
    const mobile = Boolean(
      userAgent.match(
        /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
      )
    );
    setMobileAgent(mobile);
  }, []);

  return { isMobileAgent };
}