import { useEffect, useRef, useState } from "react";

import Container from "@mui/material/Container";
import Fade from "@mui/material/Fade";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";


import CheckIcon from "@mui/icons-material/Check";

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import useOnScreen from "@hooks/useOnScreen";
import * as Icons from '../../UI/CustomIcons/index';

import { renderParagraphs } from "@helper/paragraph";
import { renderRichText } from "@helper/renderRichText";
import { ParagraphProps } from "@model/paragraphs.model";
import style from './Tab.module.scss';
import TabPanel from "./TabPanel";

interface FieldData {
  field_tab_item?: any;
}

interface TabPanelColProps {
  item: any;
  col: any;
}

function TabPanelCol(props: TabPanelColProps) {
  const { item, col } = props;
  const theme = useTheme();
  const isMobile: boolean = useMediaQuery(theme.breakpoints.down('lg'));
  const [elements, setElements] = useState([])
  let nCol= 12 / item.field_tab_item_col.length;

  let iconName = col.field_tab_item_col_icon ? col.field_tab_item_col_icon?.name?.replace(/\.[^/.]+$/, "") : false;
  const Icon = iconName ? Icons[iconName] : null;

  const startstWithUl = col?.field_tab_item_col_content?.processed?.startsWith("<ul")

  useEffect(() => {
    const processedContent = col?.field_tab_item_col_content?.processed;
  
    if (startstWithUl) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(processedContent, 'text/html');
      const elements = Array.from(doc.querySelectorAll('li'));
      setElements(elements);
    }
  }, [col?.field_tab_item_col_content?.processed]);

  return(
    <Grid item xs={10} md={12} lg={nCol} className={style.tabColumn}>
      <Fade in={true} {...{ timeout: 1000 }}>
        <div className={style.tabColumnInner}>
          <Container maxWidth={startstWithUl ? 'md': 'xl'} className={style.tabColumnInner} disableGutters={isMobile}>

            {col?.field_tab_item_col_icon?.name &&
              (<div className={`${style.icon}`}>
                <Icon />
              </div>)
            }

            {col?.field_tab_item_col_title && <Typography variant="h5" className={style.title}>{col.field_tab_item_col_title}</Typography>}
            {elements && elements.length ? (
              <List>
                {elements.map((listItem, index) => {
                  return (
                    <ListItem key={index}>
                      <ListItemIcon><CheckIcon color={'secondary'}/></ListItemIcon>
                      <ListItemText>
                        <Typography variant="bodyL">{listItem.textContent}</Typography>
                      </ListItemText>
                    </ListItem>
                  )
                })}
              </List>
              ) : (
              props.col?.field_tab_item_col_content?.processed ? (
                <Typography variant="bodyL">
                  {renderRichText(props.col?.field_tab_item_col_content?.processed)}
                </Typography>
              ) : null
            )}
          </Container>
        </div>
      </Fade>
    </Grid>
  )

}

const TabModule: React.FC<ParagraphProps<FieldData>> = ({ fieldData }) => {

  const {field_tab_item} = fieldData

  const ref: any = useRef(null);
  const onScreen: boolean = useOnScreen(ref);
  const [loaded, setLoadead] = useState(false);

  useEffect(()=> {
    if(onScreen) setLoadead(true);
  }, [onScreen])

  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);

    if (ref.current) {
      const scrollPosition = ref.current.offsetTop - 70;
      window.scrollTo({
        top: scrollPosition,
        behavior: 'smooth',
      });
    }
  };
  const theme = useTheme();
  const isMobile: boolean = useMediaQuery(theme.breakpoints.down('lg'));

  const tabsVariant: 'standard' | 'scrollable' | 'fullWidth' = field_tab_item.length > 4 || isMobile ? 'scrollable' : 'fullWidth';

  return (
    <Fade ref={ref} in={loaded} appear={false} timeout={1000} style={{transitionDelay: '100ms'}}>
      <div className={style.tabWrapper}>
        <Tabs
          value={value} onChange={handleChange}
          aria-label="tabs"
          variant={tabsVariant}
          className={style.tabs}
        >
          {field_tab_item && field_tab_item?.map((item) => <Tab key={item.id} label={item.field_tab_item_label} />)}
        </Tabs>

        {field_tab_item && field_tab_item?.map((item, index) =>  (
          <TabPanel value={value} index={index} key={`TabPanel_${item.id}`} isParagraphHolder={!!item?.field_reusable_paragraph_holder}>
            {
              item?.field_reusable_paragraph_holder ? 
                renderParagraphs( item?.field_reusable_paragraph_holder)
                : item.field_tab_item_col?.map((col, i) => <TabPanelCol key={`TabPanelCol_${item.id}_${i}`} col={col} item={item} />)
            }
          </TabPanel>
        ))}
      </div>
    </Fade>
  )
}

export default TabModule