import Image from 'next/image';
import { FC, useContext } from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { absoluteUrl } from '../../../lib/utils';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { DarkModeContext } from '@contexts/darkMode';
import Row from '@components/Modules/Utils/Row/Row';
import SocialShare from '@components/Modules/Utils/SocialShare/SocialShare';
import { convertDate } from '@helper/date';
import useWindowSize, { WindowSize } from '@hooks/useWindowSize';

import { CustomCountdown } from '@components/Common/CustomCountdown';
import { DrupalNode } from 'next-drupal';
import { useTranslation } from 'next-i18next';
import { NextRouter, useRouter } from 'next/router';
import { ParagraphModel } from '@model/paragraphs.model';
import { HeroItemModel } from './Hero';
import styles from './Hero.module.scss';

interface SlideItemProps {
  slide: ParagraphModel & HeroItemModel;
  index: number;
  node: DrupalNode;
}

const Slide: FC<SlideItemProps> = ({ slide, index, node }) => {
  const { t } = useTranslation();
  const router: NextRouter = useRouter();
  const { darkMode } = useContext(DarkModeContext);
  const size: WindowSize = useWindowSize();

  const { id, field_title, field_copyright, field_date, field_description, field_image, field_image_darkmode, field_immagine, field_immagine_darkmode, field_link, field_secondary_link, field_logo_hero, } = slide

  const buttonVariant = {
    primary: 'contained',
    secondary: 'outlined'
  }

  const getImageSlide = (): string => {
    const deskImage: string = darkMode && field_immagine_darkmode ? field_immagine_darkmode?.field_media_image?.uri?.url : field_immagine?.field_media_image?.uri?.url;
    const mobileImage: string = darkMode && field_image_darkmode ? field_image_darkmode?.field_media_image?.uri?.url : field_image?.field_media_image?.uri?.url;
    if (!size) return '';
    if (size.width < 1024 && size.width < size.height && mobileImage) return mobileImage;
    return deskImage || '';
  };

  const imageSlide: string = getImageSlide();
  const logoImage: string = field_logo_hero?.field_media_image?.uri?.url;
  // const time = `- ${t('global.date.hours')} ${getHour(node?.created, node.langcode)}`;
  const date: string = convertDate(node?.created, node.langcode);
  const isBlog: boolean = node.type === 'node--article' || router.asPath.startsWith('/next-journal');
  const getButtonVariant = (type) => buttonVariant[slide?.[type]?.options?.attributes?.cta_preset_class] || null;
  const primaryButtonVariant = getButtonVariant('field_link') || 'contained';
  const secondaryButtonVariant = getButtonVariant('field_secondary_link') || 'link';

  return (
    <Box className={styles.heroWrapper}>
      <Box className={styles.heroWrapperContent}>
        <Container maxWidth={false} className={styles.container}>
          <Row className={styles.row}>
            <Grid item xs={false} lg={1} />
            <Grid className={`${styles.content} swiper-no-swiping`} item xs={12} lg={7} >
              {logoImage && (
                <Image
                  className={styles.logoImage}
                  src={absoluteUrl(logoImage)}
                  width={field_logo_hero?.field_media_image?.resourceIdObjMeta?.width}
                  height={field_logo_hero?.field_media_image?.resourceIdObjMeta?.height}
                  alt={`logo hero`}
                  quality={100}
                />
              )}

              <Typography
                className={`${styles.title} swiper-no-swiping`}
                variant={isBlog ? "h3" : "h1"}
                component="h1"
                mt={4}
              >
                {field_title}
              </Typography>

              {node.type === "node--article" ? (
                <>
                  {field_description?.value && (
                    <Typography
                      className={styles.subtitle}
                      sx={{ 
                        mb: 0,
                        '& *': {                         
                          '@media (min-width: 1024px)': {
                            fontSize: '1.2rem',
                            lineHeight: '1.5',
                            fontWeight: '400',
                          }
                        }
                      }}
                      dangerouslySetInnerHTML={{
                        __html: field_description?.value,
                      }}
                      variant="bodyL"
                      component="div"
                    />
                  )}

                  <Typography
                    className={styles.desc}
                    sx={{ display: "inline-block" }}
                    variant="bodyS"
                    component="div"
                    mb={30}
                  >
                    {date}
                  </Typography>
                  <SocialShare node={node} />
                </>
              ) : (
                <>
                  <Typography
                    className={styles.desc}
                    dangerouslySetInnerHTML={{
                      __html: field_description?.value,
                    }}
                    style={{ display: "block" }}
                    variant="bodyL"
                    component="div"
                    mt={24}
                    mb={40}
                  />

                  {field_date && typeof field_date === "string" && (
                    <CustomCountdown
                      date={field_date}
                      timeToHide={["seconds"]}
                    />
                  )}

                  {(field_link?.url && field_link?.title != t("register")) && (
                      <Button
                        id={`${id}`}
                        variant={primaryButtonVariant}
                        href={field_link?.url}
                      >
                        {field_link?.title}
                      </Button>
                    )}

                  {(field_link?.url.startsWith("#") || !field_link?.url) && field_link?.title == t("register") && (
                      <Button
                        id={`${id}_primary`}
                        variant={primaryButtonVariant}
                        onClick={() => {
                          const anchor = document.querySelector(field_link?.url ? 
                            field_link?.url : "#registratiCta"
                          );

                          anchor.scrollIntoView({
                            behavior: "smooth",
                            block: "start",
                          });
                        }}
                      >
                        {field_link?.title}
                      </Button>
                    )}

                  {field_secondary_link?.url && (
                    <Box
                      mt={{ xs: 24, lg: 32 }}
                      textAlign={{ xs: "center", lg: "left" }}
                    >
                      <Button
                        id={`${id}_secondary`}
                        variant={secondaryButtonVariant}
                        href={field_secondary_link?.url}
                      >
                        {field_secondary_link?.title}
                      </Button>
                    </Box>
                  )}

                  {field_copyright?.value && (
                    <Typography
                      className={`${styles.copyright} swiper-no-swiping`}
                      dangerouslySetInnerHTML={{
                        __html: field_copyright?.value,
                      }}
                      style={{ display: "block" }}
                      variant="bodyL"
                      component="div"
                    />
                  )}
                </>
              )}
            </Grid>
          </Row>
        </Container>
      </Box>

      {field_immagine && (
        <Image
          className={styles.heroImage}
          width={field_immagine?.field_media_image?.resourceIdObjMeta?.width}
          height={field_immagine?.field_media_image?.resourceIdObjMeta?.height}
          src={absoluteUrl(imageSlide)}
          alt={`Hero_slide_${index}`}
          quality={100}
        />
      )}
    </Box>
  );
}

export default Slide