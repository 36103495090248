import Image from "next/image";
import { FC } from "react";

import { getThumbnailAlt, getThumbnailUrl } from "@helper/media";

import "swiper/css";

import Box from "@mui/material/Box";
import styles from "./Slider.module.scss";
import { SlideItemProps } from "./types";
import Typography from "@mui/material/Typography";
// import { Typography } from "@mui/material";

const VideoPodcastSlide: React.FC<{slide: SlideItemProps}> = ({slide}) => {
  const {
    field_slider_item_iframe: iframe,
  } = slide;

  if(!iframe) {
    return null
  }

  return (
    <Box className={`${styles.content} ${styles.iframeWrapper}`}>
      <Box
        className={`${styles.panel} ${styles[slide.field_slider_item_type]}`}
        dangerouslySetInnerHTML={{ __html: slide?.field_slider_item_iframe }}
      />
    </Box>
  )
}

const ImageSlide: React.FC<{slide: SlideItemProps}> = ({slide}) => {

  const {
    field_slider_item_image: image,
    field_title: title,
    field_description: description,
  } = slide;

  if(!image) {
    return null
  }

  return (
    <Box className={`${styles.content} ${styles.imageWrapper}`}>
      <Image
        className={styles.image}
        alt={getThumbnailAlt(image)}
        src={getThumbnailUrl(image)}
        width={
          image?.field_media_image?.resourceIdObjMeta
            ?.width
        }
        height={
          image?.field_media_image?.resourceIdObjMeta
            ?.height
        }
      />
      {(title || description?.value) && (
        <Box className={styles.imageContent}>
          {title && <Typography component="h5" variant="h5" fontWeight={700} dangerouslySetInnerHTML={{ __html: title }}/>}
          {description?.value && <Typography component="div" variant="bodyS" dangerouslySetInnerHTML={{ __html: description?.value }}/>}
        </Box>
      )}
    </Box>
      
  )
}

const SlideContent: FC<{slide: SlideItemProps}> = ({ slide }) => {

  const slideContentMap = {
    image: ImageSlide,
    video: VideoPodcastSlide,
    podcast: VideoPodcastSlide
  }

  const Component = slideContentMap[slide.field_slider_item_type] || ImageSlide;

  return (
    <Component slide={slide} />
  );
};

export default SlideContent