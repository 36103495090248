import MapGrid from "@components/Modules/ItalyMap/MapGrid";
import MapLegend from "@components/Modules/ItalyMap/MapLegend";
import { ItRegions } from "@components/Modules/ItalyMap/models/ItRegions";
import Grid from "@mui/material/Grid";
import useMediaQuery from "@mui/material/useMediaQuery";
import { theme } from "@styles/mui/theme";
import { BaseSyntheticEvent, useState } from "react";

export default function ItalyMapSection() {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Popover
  const [openedRegion, setOpenedRegion] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState<ItRegions | null>(null);
  const openRegion = (event: BaseSyntheticEvent) => {
    const targetRegion = event?.target?.id;
    if (!targetRegion) return;
    setSelectedRegion(targetRegion);
    setOpenedRegion(true);
  };
  const closeRegion = () => {
    setOpenedRegion(false);
  };
  const deselectRegion = () => {
    setSelectedRegion(null);
  };

  return (
    <Grid
      onMouseLeave={closeRegion}
      sx={{
        position: "relative",
        background: isMobile
          ? "#fff"
          : "linear-gradient(90deg, rgba(227,239,249,1) 15%, rgba(227,239,249,0) 65%)",
        overflowX: 'hidden',
        overflowY: 'scroll',
        marginBottom: "4rem",
      }}
      container
      columns={isMobile ? 1 : 10}
      width="100%"
      height={isMobile ? "auto" : 'fit-content'}
    >
      <MapGrid
        {...{
          openedRegion,
          openRegion,
          closeRegion,
          selectedRegion,
          deselectRegion,
          isMobile,
        }}
      />

      <MapLegend
        {...{
          openRegion,
          isMobile,
        }}
      />
    </Grid>
  );
}
