import React, { useEffect } from "react";

import useWindowSize, { WindowSize } from "@hooks/useWindowSize";

import Box from "@mui/material/Box";

import { Autoplay, Pagination } from "swiper";
import "swiper/css";
import { Swiper, SwiperProps, SwiperSlide } from "swiper/react";

import FadeIn from "@components/Common/FadeIn/FadeIn";
import { ParagraphProps } from "@model/paragraphs.model";
import SlideContent from "./SlideContent";
import styles from "./Slider.module.scss";
import { SlideItemProps, SliderProps } from "./types";
import useSectionSlider from "./useSectionSlider";

const SWIPER_PARAMS: SwiperProps = {
  slidesPerView: 1.3,
  spaceBetween: 18,
  modules: [Pagination, Autoplay],
  autoplay: {
    delay: 5000,
    disableOnInteraction: true,
  },
  pagination: {
    clickable: true,
  },
  loop: true,
  draggable: true,
  simulateTouch: true,
  allowTouchMove: true,
  touchMoveStopPropagation: true,
};

const Slider: React.FC<ParagraphProps<SliderProps>> = ({ fieldData }) => {

  const { field_slider_layout, field_slider_item } = fieldData;

  useEffect(() => {
    document?.querySelector("html")?.style.removeProperty("scroll-behavior");
  }, []);

  const size: WindowSize = useWindowSize();

  const { sliderSectionInlineStyle, addToRefs, panelsRef } = useSectionSlider({
    size,
    field_slider_layout,
    field_slider_item,
  });

  const showSwiper: boolean =  size && field_slider_layout == "layout-2" && size?.width < 1024;

  if (!field_slider_item || !field_slider_item.length) return;

  return (
    <FadeIn>
      <Box
        className={styles.overflowWrap}
        sx={{
          "& .swiper": {
            display: showSwiper ? "block" : "none",
          },
          "& .section-slider": {
            display: showSwiper ? "none !important" : "flex",
          },
        }}
      >
        <Swiper
          {...SWIPER_PARAMS}
          className={`${styles.SwiperSlider} ${styles.SwiperCard}`}
        >
          {field_slider_item?.map((slide: SlideItemProps) => {
            return (
              <SwiperSlide key={slide.id} className={`${styles.swiperItem}`}>
                <SlideContent slide={slide} />
              </SwiperSlide>
            );
          })}
        </Swiper>

        <section
          ref={panelsRef}
          className={`${styles.sliderContainer} ${field_slider_layout} panels ${
            field_slider_item?.length == 1 ? styles.alone : ""
          } section-slider`}
          style={sliderSectionInlineStyle}
        >
          {field_slider_item?.map((slide: SlideItemProps) => (
            <Box key={slide.id} ref={addToRefs} className={styles.panel}>
              <SlideContent slide={slide} />
            </Box>
          ))}
        </section>
      </Box>
    </FadeIn>
  );
}

export default Slider
